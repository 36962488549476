import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../AlertModel/ConfirmationDialog";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import { LoginContext } from "../../contexts/LoginContext";
import { TestContext } from "../../contexts/TestContext";
import useCreateTestResult from "../../resultHooks/useCreateTestResult";
import styles from '../../styles/QuestionWrapper.module.css';
import CustomModal from "../CustomModal/CustomModal";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Question from "../Questions/Question";
import WarningMessege from "../WarningMessege";
const QuestionWrapper = ({ remainingQuestions, testId, testStartedTime, testStartFlag, getQuestionCount, currentQuestionIndex, preview, isCourse, courseId, setSubmitFlag, submitFlag, timeOutFlag, questionObj }) => {
  const { t } = useTranslation();
  const { setTestTFlag, test, testAttemptDetails } = useContext(TestContext); //test  //TempCommit
  const { setAlertFlag, UserId } = useContext(LoginContext);
  var gblPoolLevelResult = true;
  var calculatedActualScore = 0;
  var calculatedNegativeScore = 0;
  var calculatedPositiveScore = 0;
  let SectionScoreEvaluation = [];
  // let test = JSON.parse(localStorage.getItem("AttemptedTest"))  //TempCommit
  let resumeTestId = localStorage.getItem(`${UserId}TestId`)

  console.log("courseid is", courseId);
  useEffect(() => {
    setStartQuestionTime(testStartedTime);
    setTestTFlag(testStartFlag);
  }, [testStartedTime, testStartFlag])
  const [questions, setQuestions] = useState([]);
  const [startQuestionTime, setStartQuestionTime] = useState(new Date());
  const [alertModal, setAlertModal] = useState(null);

  const result = useCreateTestResult();
  console.log(test, 'testObj123')
  useEffect(() => {
    console.log(test, 'mappingTest')
    let rtest = test?.pools["$values"].map((x, i) => {
      x.questions["$values"].forEach((y) => {
        console.log(y, "ydata")
        y.poolText = x.text;
        y.poolId = x.id;
        // y.maxQuestionForScore = x.maxQuestionForScore;
        y.isMandatoryToPass = x.isMandatoryToPass;
        y.poolScore = x.poolScore;
        y.markForReview = y?.markForReview ? y?.markForReview : false;
        y.response = y?.response ? y?.response : null;
        y.isCorrect = y?.isCorrect ? y?.isCorrect : false;
        y.hintCount = y?.hintCount ? y?.hintCount : 0;
        y.markForReviewCount = y.markForReviewCount ? y?.markForReviewCount : 0;
        y.TOC = true;

      });
      test.isSubmitted = false;
      return x.questions["$values"];
    });
    console.log(rtest, 'rtest123')
    rtest = rtest.flat();
    console.log(rtest, 'flattedrtest123')
    const qPerPage = test.questionsPerPage;

    let c = 0;
    let currentPool = "";
    let index = 0;

    let mdata = rtest.reduce((acc, cur) => {
      if (currentPool == "") {
        currentPool = cur.poolText;
      }

      if (c == qPerPage || currentPool != cur.poolText) {
        c = 0;
        currentPool = cur.poolText;
        index++;
      }

      if (!acc[index]) {
        acc[index] = [];
      }

      acc[index].push(cur);
      c++;

      return acc;
    }, []);

    setQuestions(mdata);

    console.log("mdata", mdata);
  }, []);

  useEffect(() => {
    if (questions && questions.length > 0) {
      getDuration(currentQuestionIndex == 0 ? currentQuestionIndex : currentQuestionIndex - 1);
    }
    // if (testId !== resumeTestId) {
    localStorage.setItem(`${UserId}AttemptedTest`, JSON.stringify(test)); //TempCommit
    // }
    localStorage.setItem(`${UserId}TestId`, test.testId)   //commented in last changes -  testAttemptDetails?.testId)
    localStorage.setItem(`${UserId}currentQuestionIndex`, currentQuestionIndex)
  }, [currentQuestionIndex])

  const getDuration = (currentQuestionIndex) => {
    let questionSubmitTime = new Date();
    let dateDiff = questionSubmitTime - startQuestionTime;
    setStartQuestionTime(questionSubmitTime);
    let multipleQuestions = dateDiff / questions[currentQuestionIndex].length;
    for (let a = 0; a < questions[currentQuestionIndex].length; a++) {
      questions[currentQuestionIndex][a].qDuration = questions[currentQuestionIndex][a].qDuration ? questions[currentQuestionIndex][a].qDuration + multipleQuestions : multipleQuestions;
      questions[currentQuestionIndex][a].noOfVisits = questions[currentQuestionIndex][a].noOfVisits ? questions[currentQuestionIndex][a].noOfVisits + 1 : 1;
      // questions[currentQuestionIndex][a].nHintOpen = nHints;
      // setNHints(0);
    }
  }

  useEffect(() => {
    if (submitFlag == true)
      handleSubmit()
  }, [submitFlag])

  useEffect(() => {
    if (timeOutFlag == true)
      onSubmitTest();
  }, [timeOutFlag])

  useEffect(() => {
    getQuestionCount(questions);
  }, [questions])

  const updateQuestion = (question) => { };



  const captureResult = async (questions, score, isPassed) => {

    testAttemptDetails.isCourse = isCourse;
    testAttemptDetails.result = isPassed == null ? 3 : isPassed == true ? 2 : 1;
    testAttemptDetails.actualScore = calculatedActualScore;
    testAttemptDetails.negativeScore = calculatedNegativeScore;
    testAttemptDetails.positiveScore = calculatedPositiveScore;
    testAttemptDetails.sectionScore = JSON.stringify(score);
    testAttemptDetails.attemptAnswers = JSON.stringify(questions);
    testAttemptDetails.attemptedOn = new Date()
    testAttemptDetails.updatedDate = new Date()
    testAttemptDetails.isSubmited = true;
    testAttemptDetails.courseId = courseId;
    console.log("testResult", testAttemptDetails);

    await result.mutateAsync(testAttemptDetails);
  };

  const handleSubmit = async () => {
    setAlertModal(
      <CustomModal
        modal={true}
        setModal={hideAlertModal}
        content={
          <ConfirmationDialog
            unsolvedQuestions={remainingQuestions}
          />
        }
        onOkClick={onSubmitTest}
        showOk={true}
        okButtonMessage={t('yes')}
        cancelButtonMessage={t('no')}
        showCancel={true}
        onCancelClick={hideAlertModal}
        title={t('warn')}
        fullWidth={false}
        hideTitle={true}
      />
    );

  };

  const onSubmitTest = async () => {
    let online = navigator.onLine;
    console.log(online, 'onlineStatus')
    if (online == false) {
      setAlertModal(
        <CustomModal
          modal={true}
          setModal={hideAlertModal}
          content={
            <WarningMessege
              message={t("noInternet")}
            />
          }
          showOk={false}
          okButtonMessage={'Ok'}
          showCancel={true}
          cancelButtonMessage={'Ok'}
          onOkClick={hideAlertModal
          }
          onCancelClick={hideAlertModal}
          title={`Warning`}
          maxWidth="sm"
          hideTitle={true}
          fullWidth={false}
        />
      );
      return
    }
    testAttemptDetails.currentPageIndex = 2;
    console.log("redirected to feedback screen - 2",)
    setAlertFlag(false);
    if (test.isSubmitted == false) {
      test.isSubmitted = true;
      setTestTFlag(false)

      getDuration(currentQuestionIndex);

      questions[currentQuestionIndex].isSubmitted = true;

      let calculatedScores = calculateScores();
      console.log(calculatedScores, 'calculatedScores')

      let isPassed = test.passingScore == null ? null : gblPoolLevelResult && calculatedActualScore >= test.passingScore ? true : false;

      if (!preview) {
        await captureResult(questions, SectionScoreEvaluation, isPassed);
      }
      // to remove localTest's data on submit
      localStorage.removeItem(`${UserId}UserId`);
      localStorage.removeItem(`${UserId}currentQuestionIndex`);
      localStorage.removeItem(`${UserId}TestId`);
      localStorage.removeItem(`${UserId}remainingTime`);
      localStorage.removeItem(`${UserId}AttemptedTest`);

    } else {
      return
    }
    console.log("questions", questions)
  }
  const hideAlertModal = (event, reason) => {
    if (reason) console.log("reason", reason);
    setSubmitFlag(false)
    setAlertModal(null);
  };

  const calculateScores = () => {
    let scores = {};

    scores = test.pools["$values"]?.map((currPool, Iterator,) => {
      var correctResponses = 0;
      var maxQuestionForScore = 0;
      var inCorrectResponses = 0;
      var points = 0;
      var negativePoints = 0;
      let SectionScore = {};

      currPool?.questions["$values"]?.map((Questions, Iterator) => {
        points = Questions.points;
        negativePoints = Questions.negativePoints;
        if (Questions.response != null) {
          if (Questions.isCorrect == true) {
            correctResponses++;
          } else {
            inCorrectResponses++;
          }
        }
      })

      currPool.positiveScore = correctResponses * points;   //positive score
      currPool.negativeScore = inCorrectResponses * negativePoints;

      if (currPool.maxQuestionForScore != null && currPool.maxQuestionForScore > 0 && correctResponses >= currPool.maxQuestionForScore) {
        correctResponses = currPool?.maxQuestionForScore;
      }

      if (currPool.maxQuestionForScore != null && currPool.maxQuestionForScore > 0 && (correctResponses + inCorrectResponses) > currPool.maxQuestionForScore) {
        inCorrectResponses = currPool.maxQuestionForScore - correctResponses
      }

      currPool.ActualPoolScore = correctResponses * points + inCorrectResponses * negativePoints;

      // if (currPool.maxQuestionForScore != null && currPool.maxQuestionForScore >= 0) {
      //   maxQuestionForScore = currPool?.maxQuestionForScore;
      // }

      // if (maxQuestionForScore == 0) {

      //   currPool.positiveScore = correctResponses * points;   //positive score
      //   currPool.negativeScore = inCorrectResponses * negativePoints;
      //   currPool.ActualPoolScore = correctResponses * points + inCorrectResponses * negativePoints;

      // } else {
      //   //If correctResponses are greater than eqaual to maxQuestionForscore then calculate points like belaow
      //   if (correctResponses >= maxQuestionForScore) {
      //     currPool.positiveScore = correctResponses * points;
      //     currPool.ActualPoolScore = maxQuestionForScore * points;

      //   } else {
      //     // If correctResponse plus negativeResponses are greater than maxQuestionForscore then calculate points like below
      //     if ((parseInt(correctResponses) + parseInt(inCorrectResponses)) > maxQuestionForScore) {
      //       currPool.positiveScore = correctResponses * points;
      //       currPool.negativeScore = ((parseInt(maxQuestionForScore) - parseInt(correctResponses)) * negativePoints);
      //       currPool.ActualPoolScore = (correctResponses * points) + ((parseInt(maxQuestionForScore) - parseInt(correctResponses)) * negativePoints)

      //     } else {
      //       currPool.positiveScore = correctResponses * points;   //positive score
      //       currPool.negativeScore = inCorrectResponses * negativePoints;
      //       currPool.ActualPoolScore = correctResponses * points + inCorrectResponses * negativePoints;
      //     }
      //   }

      // }

      SectionScore.id = currPool.id;
      SectionScore.text = currPool.text;
      SectionScore.poolScore = currPool.ActualPoolScore;
      SectionScore.totalPoolScore = currPool.poolScore;
      SectionScore.positiveScore = currPool.positiveScore;
      SectionScore.negativeScore = currPool.negativeScore;
      SectionScoreEvaluation.push(SectionScore);
      console.log(SectionScoreEvaluation, 'SectionScoreEvaluation123')
      currPool.pass =
        currPool.ActualPoolScore >= currPool.poolScore ? true : false;
      currPool.masteryPass =
        currPool.ActualPoolScore >= currPool.masteryScore ? true : false;   //nee to work for 0 mastery score
      currPool.isMandatoryToPassPool = currPool.isMandatoryToPass;

      if (currPool.isMandatoryToPass == true && currPool.pass == false) {
        gblPoolLevelResult = false;
      }

      calculatedActualScore = calculatedActualScore + currPool?.ActualPoolScore;
      calculatedNegativeScore = calculatedNegativeScore + currPool?.negativeScore;
      calculatedPositiveScore = calculatedPositiveScore + currPool?.positiveScore;

      return currPool;
    }
    )
    console.log(scores, 'in Scores');
    return scores;
  }
  console.log(questions, 'questions')
  return (
    <GridContainer>
      {questions && questions.length > 0 && (
        <GridItem xs={12} sm={12} md={12} lg={12} style={{ borderBottom: '1px solid #EC9706' }}>
          <h4 style={{ marginLeft: '2%', color: '#EC9706', fontWeight: '400' }}>{t('Section')}  - {questions[currentQuestionIndex][0].poolText} </h4>
        </GridItem>
      )}
      <GridItem xs={12} sm={12} md={12} lg={12} className={styles.gridOnMobile}>
        <Card>
          <CardBody className={styles.cardbody}>
            {questions &&
              questions.length > 0 &&
              questions[currentQuestionIndex].map((x, i) => (
                <Question
                  qNoes={test.questionsPerPage * currentQuestionIndex + i + 1}
                  key={i}
                  question={x}
                  questionObj={questionObj}
                  updateQuestion={updateQuestion}
                  preview={preview}
                />

              ))}
          </CardBody>
        </Card>
      </GridItem>
      {alertModal}
    </GridContainer>
  );
};

export default QuestionWrapper;
